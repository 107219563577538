/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/material.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.text-xl{
    font-size: 18px!important;
    margin: 0!important;
}

.dataTables_wrapper .dataTables_length select {
    min-width:80px!important;
}

.forcefull{
  width: 100%!important;
  min-width: 100%!important;
}

.searchParameterObjects {
  padding:10px;border-radius:10px;border:solid 0.2px; margin:4px
}